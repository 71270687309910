<template>
  <v-container>
    <v-row>
      <h1 class="headline">
        {{ item.title }}
        <Status :statusEnumeration="statusEnumeration" entity="employeeneed" />
      </h1>
    </v-row>
    <EntityPropertiesEdit :save="update" :entity="item" :properties="itemProperties"/>
    <EventList :eventsGroups="eventsGroups" :editable="true" />
   </v-container>
</template>

<script>
import { stringify } from "query-string";
import Status from "@/components/Status.vue";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue"
import EventList from "@/components/EventsList.vue";

const API_URL = process.env.VUE_APP_BASE_API_URL + "/employeeneed";

export default {
  name: "employeeneed",
  components: {
    Status,
    EventList,
    EntityPropertiesEdit
  },
  data: () => ({
    statusEnumeration: [
      {
        name: "new",
        display: "Новая",
        color: "dark blue blue--text text--darken-1"
      },
      {
        name: "searching",
        display: "Подбираем",
        color: "dark green green--text text--darken-1"
      },
      {
        name: "archived",
        display: "В архиве",
        color: "dark grey grey--text text--darken-1"
      }
    ],
    itemProperties: [
    {
      icon: "mdi-briefcase",
      caption: "Название проекта (можно также укзать организацию)",
      placeholder: "Проект не указан",
      name: "project",
      type: "string",
      hint: "hint",
      message: "Введите название проекта"
    },
    {
      icon: "mdi-chart-bar",
      caption: "Уровень сотрудника (junior, middle, senior и тд)",
      placeholder: "Уровень не указан",
      name: "level",
      type: "string",
      hint: "hint",
      message: "Введите уровень сотрудника"
    },
    {
      icon: "mdi-multiplication",
      caption: "Специализация сотрудника (можно навыки через запятую)",
      placeholder: "Специализация не определена",
      name: "specialization",
      type: "string",
      hint: "hint",
      message: "Введите специализацию"
    },
    {
      icon: "mdi-map-marker",
      caption: "Локация предстоящей высадки сотрудника (Москва, Питер и тд)",
      placeholder: "Локация не указана",
      name: "location",
      type: "string",
      hint: "hint",
      message: "Введите локацию высадки сотрудник"
    },
    {
      icon: "mdi-google-circles-extended",
      caption: "Количество открытых позиций",
      placeholder: "Количество позиций не определено",
      name: "quantity",
      type: "string",
      hint: "hint",
      message: "Введите количество открытых позиций"
    }
    ],    
    item: { },
    eventsGroups: [
      {
        name: "global",
        displayName: "Без даты",
        color: "blue lighten-5",
        filter: x => {
          //return !x.resolution || x.resolution == "confirmed";
          return true || x;
        }
      },
      {
        name: "yesterday",
        displayName: "Вчера",
        color: "red lighten-5",
        filter: x => {
          return true || x;
        }
      },
      {
        name: "today",
        displayName: "Сегодня",
        color: "teal lighten-5",
        filter: x => {
          return true || x;
        }
      },
      {
        name: "tomorrow",
        displayName: "Завтра",
        color: "lime lighten-5",
        filter: x => {
          return true || x;
        }
      }
    ]
  }),
  computed: {
    statusDisplay() {
      const d = this.statusEnumeration.find(x => x.name == this.item.status);
      return d ? d.display : "Статус не определен";
    },
    statusColor() {
      const d = this.statusEnumeration.find(x => x.name == this.item.status);
      return d ? d.color : "success";
    }
  },
  methods: {
    update(item) {
      // eslint-disable-next-line no-console
      console.log(`updateItem(${JSON.stringify(item)})`);
      // make post request
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(item),
        headers: {
          "content-type": "application/json"
        }
      })
        .then(resp => resp.json())
        .then(result => {
          // eslint-disable-next-line no-console
          console.log("response is ");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            // update item view
            this.item = result;
          }
        });
    }
  },

  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.$route.params.id);

    // eslint-disable-next-line no-console
    console.log("loaded employeeneeds");
    fetch(
      `${process.env.VUE_APP_BASE_API_URL}/employeeneed?${stringify(
        this.$route.params
      )}`
    )
      .then(resp => resp.json())
      .then(resp_json => {
        // eslint-disable-next-line no-console
        console.log(resp_json);
        this.item = resp_json;
      });
  }
};
</script>
